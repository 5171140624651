import { Input } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SearchIcon } from 'assets/images/search-dashboard.svg';

import { Loader } from 'components/loader/loader';
import { useGetShipmentReportsQuery } from 'store/api/documents';

import { useDebounce } from 'usehooks-ts';
import s from './All.module.scss';
import { ShipmentReportsTable } from './ShipmentReportsTable';
import { NotFound, NotFoundContainer } from 'components/not-found';

export const Label = ({ text }: { text: string }) => {
  const { t } = useTranslation();

  return (
    <div className={s['label-container']}>
      <span className={s['label-text']}>{t(text)}</span>
    </div>
  );
};

const DEFAULT_COLUMNS = [
  'number',
  'agreement',
  'period',
  'actualQuantity',
  'statusId',
  'orgSupplier',
  'orgBuyer',
];

function All() {
  const { t } = useTranslation();

  const [filter, setFilter] = useState<string>('');
  const filterDebounced = useDebounce(filter, 1000);

  const { data, isFetching } = useGetShipmentReportsQuery({
    searchQuery: filterDebounced,
  });

  const handleFilterValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFilter(value);
  };

  return (
    <>
      <span className={s['title']}>{t('Shipment reports')}</span>
      <div className={s['container-content']}>
        <div className={s['header']}>
          <div className={s['wrapper-search']}>
            <div className={s['search']}>
              <Input
                allowClear
                value={filter ?? ''}
                prefix={<SearchIcon />}
                placeholder={t('Search...')}
                onChange={handleFilterValue}
                style={{ borderRadius: '0px', height: '40px' }}
              />
            </div>
          </div>
        </div>
        <div className={s['main']}>
          {isFetching ? (
            <Loader size="big" />
          ) : (
            <>
              {data?.length === 0 ? (
                <NotFoundContainer>
                  <NotFound
                    notFoundTitle=""
                    hasSearch={false}
                    title="Отчеты по отгрузкам"
                    subtitle="отсутствуют"
                  />
                </NotFoundContainer>
              ) : (
                <ShipmentReportsTable data={data} selectedColumns={DEFAULT_COLUMNS} />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default All;
