import { StatusDocument } from 'components/status/status-document';
import { useTranslation } from 'react-i18next';
import { ShipmentReport } from 'store/api/types/documents';
import s from './table.module.scss';

import { useNavigate } from 'react-router-dom';

import { AppRoutes } from 'config/routes';

import dayjs from 'dayjs';
import { useGetDocumentStatusesQuery } from 'store/api/documents';
import { useGetMyOrganizationsQuery } from 'store/api/organizations';
import { Organization } from 'store/api/types';
import { DefaultDateFormat } from 'utils/date';

const getColumnStyle = (column: string) => {
  switch (column) {
    case 'deliveryBasis':
    case 'quantity':
    case 'number':
      return { maxWidth: 30, justifyContent: 'center' };
    case 'agreement':
      return { minWidth: 350, maxHeight: 80, textOverflow: 'ellipsis' };
    case 'statusId':
      return { minWidth: 135 };
    default:
      return { minWidth: 100 };
  }
};

export const TableRow = ({
  selectedColumns,
  item,
  isDetails,
  onSelectRow,
  selectedRows,
}: {
  isDetails?: boolean;
  onSelectRow?: (value: string) => void;
  selectedRows?: string[];
  item: ShipmentReport;
  selectedColumns: string[];
}) => {
  const navigate = useNavigate();

  const { data: myOrganizations } = useGetMyOrganizationsQuery();
  const currentOrganization: Organization | undefined = myOrganizations?.[0];

  const { data: statuses } = useGetDocumentStatusesQuery();

  const { id, statusId, orgSupplier } = item;

  const status = statuses?.find((item: any) => item.id === statusId);
  const isOwner = currentOrganization?.id === orgSupplier;

  const { Home, ShipmentReports } = AppRoutes.Dashboard;

  const handleDetailContract = (id: string) => {
    navigate(Home + ShipmentReports.Home + `/${id}`);
  };

  const handleKeyDown = (id: string, event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleDetailContract(id);
    }
  };

  return (
    <div
      onKeyDown={(e) => handleKeyDown(id, e)}
      onClick={(e) => {
        e.stopPropagation();
        handleDetailContract(id);
      }}
      key={item.id}
      style={{ width: isDetails ? '100vw' : '85vw' }}
      className={s['table-row']}
      role="button"
      tabIndex={0}
    >
      {selectedColumns.map((column: string) => {
        return (
          <TableItem key={column} content={(item as any)[column]} data={item} column={column} />
        );
      })}
    </div>
  );
};

export const TableItem = ({
  data,
  content,
  column,
}: {
  data: ShipmentReport;
  content: JSX.Element;
  column: string;
}) => {
  const { data: myOrganizations } = useGetMyOrganizationsQuery();
  const currentOrganization: Organization | undefined = myOrganizations?.[0];

  const { data: statuses } = useGetDocumentStatusesQuery();

  const { statusId, orgSupplier } = data;

  const status = statuses?.find((item: any) => item.id === statusId);
  const isOwner = currentOrganization?.id === orgSupplier;

  if (column === 'statusId') {
    return (
      <div style={getColumnStyle(column)} className={s['table-column']}>
        <StatusDocument text={status?.alias} isOwner={isOwner} />
      </div>
    );
  }

  if (
    column?.toLocaleLowerCase()?.includes('date') ||
    column?.toLocaleLowerCase()?.includes('createdat')
  ) {
    return (
      <div className={s['table-column']}>
        <span className={s['column-text']}>
          {dayjs((content ?? '')?.toString()).format(DefaultDateFormat)}
        </span>
      </div>
    );
  }

  return (
    <div style={getColumnStyle(column)} className={s['table-column']}>
      <span className={s['column-text']}>{content}</span>
    </div>
  );
};

export const ShipmentReportsTable = ({
  data,
  isDetails,
  selectedColumns,
  onSelectRow,
  selectedRows,
}: {
  isDetails?: boolean;
  selectedRows?: string[];
  onSelectRow?: (value: string) => void;
  selectedColumns: string[];
  data: ShipmentReport[];
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ width: isDetails ? '100vw' : '85vw' }} className={s['table']}>
      <div style={{ width: isDetails ? '100vw' : '85vw' }} className={s['table-header']}>
        {selectedColumns.map((item) => (
          <div style={getColumnStyle(item)} key={item} className={s['table-column']}>
            <span className={s['header-text']}>{t(`ShipmentReport.${item}`)}</span>
          </div>
        ))}
      </div>

      {data.map((item: ShipmentReport) => (
        <TableRow
          isDetails={isDetails}
          selectedRows={selectedRows}
          onSelectRow={onSelectRow}
          key={item.agreement?.toString()}
          item={item}
          selectedColumns={selectedColumns}
        />
      ))}
    </div>
  );
};
