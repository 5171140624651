import { AppRoutes } from 'config/routes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { toBase64 } from 'helpers';
import { useTranslation } from 'react-i18next';
import {
  useCreateSupplyProductionDocumentMutation,
  useGetCoreDocumentsQuery,
  useGetShipmentTasksSchemeQuery,
  useSignDocumentMutation,
  useUpdateShipmentTaskMutation,
} from 'store/api/documents';
import { useHandleMutation } from 'utils/handleMutation';
import {
  generateModelFromScheme,
  generateValidationSchema,
  parseSteps,
} from 'utils/sheme-parser';

export const useNewTask = () => {
  const { id: documentId } = useParams();
  const navigate = useNavigate();

  const {
    i18n: { language },
  } = useTranslation();

  const path = useLocation().pathname;
  const { Home, Contracts } = AppRoutes.Dashboard;

  const [step, setStep] = useState<number>(1);

  const [contractId, setContractId] = useState<string | undefined>();

  const { data: [document] = [], isFetching } = useGetCoreDocumentsQuery(
    { ids: [contractId ?? ''] },
    { skip: !contractId },
  );

  const [signDocument, signDocumentMutation] = useSignDocumentMutation();

  const { data: shipmentTaskDocumentScheme } = useGetShipmentTasksSchemeQuery(
    {
      documentId: documentId ?? '',
    },
    { skip: !documentId },
  );

  useCreateSupplyProductionDocumentMutation();
  const [updateShipmentTask, updateShipmentTaskMutation] = useUpdateShipmentTaskMutation();

  const dropDownOptions = useMemo(
    () =>
      [
        shipmentTaskDocumentScheme && {
          value: 'ShipmentTask',
          label: 'Задание на поставку',
          scheme: shipmentTaskDocumentScheme,
        },
      ]?.filter((item) => item),
    [shipmentTaskDocumentScheme],
  );

  const [currentSelect, setCurrentSelect] = useState(dropDownOptions[0]);
  const isApproval = path.includes(AppRoutes.Dashboard.Contracts.ContractApproval);

  const handleSign = useCallback(
    async (id?: string, version?: number) => {
      await signDocument({
        signature: '',
        detachedSignature: null,
        documentId: id ?? document?.id ?? '',
        documentVersion: version ?? document?.version,
      });
    },
    [signDocument, document?.id, document?.version],
  );

  useEffect(() => {
    if (contractId?.length && document?.id && document?.version) {
      // handleSign();
    }
  }, [contractId, document?.id, document?.version]);

  const onSuccessCreate = useCallback(async (id: string) => {
    setContractId(id);
    handleClose();
  }, []);

  const handleClose = useCallback(() => {
    navigate(Home + AppRoutes.Dashboard.ShipmentTasks.Home);
  }, [navigate]);

  const onSuccessSign = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const onErrorSign = useCallback((err: any) => {
    console.error('Error sign', err);
  }, []);

  useHandleMutation({
    ...updateShipmentTaskMutation,
    onSuccess: onSuccessCreate,
  });

  useHandleMutation({
    ...signDocumentMutation,
    onSuccess: onSuccessSign,
    onError: onErrorSign,
  });

  useEffect(() => {
    setCurrentSelect(dropDownOptions[0]);
  }, [dropDownOptions, shipmentTaskDocumentScheme]);

  const { currentScheme, model, steps, stepsLabels } = useMemo(() => {
    return {
      currentScheme: currentSelect?.scheme,
      model: generateModelFromScheme(currentSelect?.scheme, language),
      ...parseSteps(currentSelect?.scheme),
    };
  }, [currentSelect]);

  const getValidationSchema = () => {
    if (isApproval) {
      return Yup.object().shape({});
    }
    return generateValidationSchema(currentScheme, step);
  };

  return {
    step,
    steps,
    model,
    setStep,
    handleSign,
    isApproval,
    stepsLabels,
    handleClose,
    currentSelect,
    currentScheme,
    dropDownOptions,
    setCurrentSelect,
    updateShipmentTask,
    getValidationSchema,
    isLoading: signDocumentMutation.isLoading || updateShipmentTaskMutation.isLoading,
  };
};
