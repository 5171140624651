import { Divider } from 'antd';
import React, { ReactNode } from 'react';

interface PageTemplateProps {
  children: ReactNode[];
}

export const NewRequestPageTemplate: React.FC<PageTemplateProps> = ({ children }) => {
  return (
    <div style={{ gap: 24, display: 'flex', flexDirection: 'column' }}>
      {children[0]}

      <Divider style={{ marginTop: 0, marginBottom: 0 }} />

      {children[1]}
      {children[2]}
      {children[3]}
      {children[4]}

      <Divider style={{ marginTop: 0, marginBottom: 0 }} />

      <div style={{ display: 'flex', gap: 16, flexDirection: 'row' }}>
        <div style={{ flex: 0.5 }}>{children[5]}</div>
        <div style={{ flex: 1 }}>{children[6]}</div>
      </div>

      <Divider style={{ marginTop: 0, marginBottom: 0 }} />

      <div style={{ gap: 4, display: 'flex', flexDirection: 'column' }}>
        {children[7]}
        {children[8]}
      </div>

      <div style={{ gap: 4, display: 'flex', flexDirection: 'column', marginTop: 16 }}>
        {children.splice(9).map((item) => item)}
      </div>
    </div>
  );
};
